import React from 'react';
import "./style.scss"
import { v4 as uuid } from 'uuid';
import Row from './row';


const Group = ({ group }) => {
  const { name, questions } = group;
  const id = name.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
  return (
    <section className="faqGroup" id={id}>
      <div className="container">
        <div className="faqGroup__wrapper">
          <div className="faqGroup__column--heading">
            <h2 className="heading heading--dash heading--bigNormal">
              {name}
            </h2>
          </div>
          <div className="faqGroup__column--description">
            {questions.map(question => (
              <Row
                key={uuid()}
                answer={question.answer}
                questions={question.question}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Group
