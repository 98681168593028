import React from 'react';
import { useState, useRef } from 'react';
import { removeHost } from "../../utils/functions";

const Row = ({ answer, questions }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState(0);
  const answerRef = useRef();
  const answerWrapperRef = useRef();
  const tootleActive = (e) => {
    e.preventDefault();
    const height = answerRef.current.getBoundingClientRect().height;
    answerWrapperRef.current.style.maxHeight = height;
    setActive(!active);
    if (!active) {
      setHeight(height)
    }
    else {
      setHeight(0)
    }
  }
  return (
    <div className={`faqRow ${active ? 'faqRow--active' : ''}`}>
      <a href="#" className="faqRow__questions" onClick={(e) => { tootleActive(e) }}>{questions}
        <span className="faqRow__questions__icon"></span></a>
      <div className="faqRow__answer" style={{ maxHeight: height }} ref={answerWrapperRef} >
        <div className="faqRow__answer__wrapper" dangerouslySetInnerHTML={{ __html: removeHost(answer) }} ref={answerRef}>
        </div>
      </div>
    </div>
  )
}
export default Row
