import React from 'react';
import { removeHost } from "../../utils/functions";

const More = ({ description, heading, buttonContact, buttonReadMore }) => {

  return (
    <section className="moreQuestions">
      <div className="container">
        <div className="moreQuestions__wrapper">
          <h2 className="heading heading--dash heading--normal heading--center">{heading}</h2>
          <div className="content" dangerouslySetInnerHTML={{ __html: removeHost(description) }}>

          </div>
          <div className="moreQuestions__buttons">
            <div className="moreQuestions__buttons__item">
              <a href={removeHost(buttonContact.url)} className="btn">
                {buttonContact.title}
              </a>
            </div>
            <div className="moreQuestions__buttons__item">
              <a href={removeHost(buttonReadMore.url)} className="btn btn--secondary">
                {buttonReadMore.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default More
